var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.string.js");require("./metabase.lib.dispatch.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.id.js");require("./metabase.lib.schema.mbql_clause.js");require("./metabase.lib.schema.temporal_bucketing.js");require("./metabase.types.js");require("./metabase.util.malli.registry.js");
'use strict';var AK,CK,EK,HK,KK;$CLJS.xK=function(a){return $CLJS.Hh($CLJS.q($CLJS.fB),a,$CLJS.oF)};$CLJS.yK=new $CLJS.M("lib","source-name","lib/source-name",1931622490);$CLJS.zK=new $CLJS.M(null,"column-ref","column-ref",2018188376);AK=new $CLJS.M("metabase.lib.schema.ref","aggregation-options","metabase.lib.schema.ref/aggregation-options",-15566487);$CLJS.BK=new $CLJS.M(null,"operators","operators",-2064102509);
CK=new $CLJS.M("metabase.lib.schema.ref","field.options","metabase.lib.schema.ref/field.options",408316031);$CLJS.DK=new $CLJS.M(null,"stage-number","stage-number",-1752729638);EK=new $CLJS.M("metabase.lib.schema.ref","field.literal","metabase.lib.schema.ref/field.literal",-1228060748);$CLJS.FK=new $CLJS.M(null,"min-value","min-value",-1119123315);$CLJS.GK=new $CLJS.M(null,"dimensions","dimensions",-254818097);
HK=new $CLJS.M("metabase.lib.schema.ref","field.id","metabase.lib.schema.ref/field.id",-956546909);$CLJS.IK=new $CLJS.M(null,"max-value","max-value",687805168);$CLJS.JK=new $CLJS.M("metabase.lib.schema","query","metabase.lib.schema/query",-1207387140);KK=new $CLJS.M("metabase.lib.schema.ref","field.literal.options","metabase.lib.schema.ref/field.literal.options",-860446668);$CLJS.LK=new $CLJS.M(null,"null","null",-180137709);
$CLJS.MK=new $CLJS.M("metabase.lib.drill-thru","drill-thru","metabase.lib.drill-thru/drill-thru",-67424984);$CLJS.Y(CK,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ss,$CLJS.wD,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.vj,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.qF,new $CLJS.h(null,1,[$CLJS.yr,!0],null),$CLJS.eD],null)],null)],null));$CLJS.Y(KK,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ss,CK,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.vj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.tA,$CLJS.AD],null)],null)],null));$CLJS.Y(EK,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.gr,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Gq,$CLJS.kF],null),KK,$CLJS.rD],null));
$CLJS.Y(HK,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.gr,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Gq,$CLJS.kF],null),CK,$CLJS.fK],null));
$CLJS.JF.g($CLJS.kF,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.kr,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.gr,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Gq,$CLJS.kF],null),CK,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.dr,$CLJS.fK,$CLJS.rD],null)],null),new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.jr,new $CLJS.h(null,2,[$CLJS.ui,function(a){return $CLJS.sd(a)?($CLJS.I(a,0,null),$CLJS.I(a,1,null),a=$CLJS.I(a,2,null),$CLJS.WA(a)):null},$CLJS.ts,"Invalid :field clause ID or name: must be a string or integer"],null),new $CLJS.P(null,2,
5,$CLJS.Q,[$CLJS.lA,HK],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.kA,EK],null)],null)],null));$CLJS.qE($CLJS.kF,$CLJS.oF);$CLJS.LD.m(null,$CLJS.kF,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);$CLJS.I(a,2,null);a=$CLJS.zz($CLJS.mi,$CLJS.tA)(b);return $CLJS.n(a)?a:$CLJS.ZD});$CLJS.xE($CLJS.qA,$CLJS.H([$CLJS.rD]));$CLJS.LD.m(null,$CLJS.qA,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);$CLJS.I(a,2,null);a=$CLJS.zz($CLJS.mi,$CLJS.tA)(b);return $CLJS.n(a)?a:$CLJS.ZD});
$CLJS.qE($CLJS.qA,$CLJS.oF);$CLJS.Y(AK,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ss,$CLJS.wD,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.vj,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.T,new $CLJS.h(null,1,[$CLJS.yr,!0],null),$CLJS.rD],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.mD,new $CLJS.h(null,1,[$CLJS.yr,!0],null),$CLJS.rD],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.yK,new $CLJS.h(null,1,[$CLJS.yr,!0],null),$CLJS.rD],null)],null)],null));
$CLJS.JF.g($CLJS.$E,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.gr,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Gq,$CLJS.$E],null),AK,$CLJS.qj],null));$CLJS.LD.m(null,$CLJS.$E,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);$CLJS.I(a,2,null);a=$CLJS.zz($CLJS.mi,$CLJS.tA)(b);return $CLJS.n(a)?a:$CLJS.ZD});$CLJS.qE($CLJS.$E,$CLJS.oF);$CLJS.xE($CLJS.IE,$CLJS.H([$CLJS.Zr,$CLJS.hj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ok,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.MJ],null)],null)]));$CLJS.qE($CLJS.IE,$CLJS.oF);
$CLJS.xE($CLJS.fF,$CLJS.H([$CLJS.Zr,$CLJS.ZD,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ok,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.KJ],null)],null)]));$CLJS.qE($CLJS.fF,$CLJS.oF);
$CLJS.Y($CLJS.oF,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.kr,$CLJS.ND,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.xi,new $CLJS.h(null,1,[$CLJS.Es,function(){return["Valid reference, must be one of these clauses: ",$CLJS.hs(", ",$CLJS.Cz($CLJS.Ed,$CLJS.tk.g($CLJS.q($CLJS.fB),$CLJS.oF)))].join("")}],null),function(a){a=$CLJS.I(a,0,null);return $CLJS.xK(a)}],null)],null));
var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./metabase.lib.common.js");require("./metabase.lib.dispatch.js");require("./metabase.lib.equality.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.metadata.js");require("./metabase.lib.metadata.calculation.js");require("./metabase.lib.options.js");require("./metabase.lib.ref.js");require("./metabase.lib.schema.js");require("./metabase.lib.schema.aggregation.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.metadata.js");require("./metabase.lib.temporal_bucket.js");require("./metabase.lib.types.isa.js");require("./metabase.lib.util.js");require("./shadow.js.shim.module$ttag.js");require("./clojure.string.js");require("./metabase.util.js");require("./metabase.util.malli.js");
'use strict';var R3,T3,V3,W3,X3,Y3,Z3,Fra;R3=function(a,b){if(null!=a&&null!=a.vg)a=a.vg(a,b);else{var c=R3[$CLJS.va(null==a?null:a)];if(null!=c)a=c.g?c.g(a,b):c.call(null,a,b);else if(c=R3._,null!=c)a=c.g?c.g(a,b):c.call(null,a,b);else throw $CLJS.$a("MetadataProvider.metric",a);}return a};$CLJS.S3=function(a,b){return R3($CLJS.c_(a),b)};
T3=function(a,b,c){var d=$CLJS.DV(a,b);d=$CLJS.O(d);d=$CLJS.K.g(d,$CLJS.$E);d=$CLJS.qG($CLJS.Yj.j($CLJS.Tg([c]),$CLJS.yD,$CLJS.dd),d);if(!$CLJS.n(d))throw $CLJS.Uh($CLJS.hD("No aggregation with uuid {0}",$CLJS.H([c])),new $CLJS.h(null,3,[$CLJS.sr,c,$CLJS.GE,a,$CLJS.DK,b],null));return d};$CLJS.U3=function(a){return $CLJS.I1($CLJS.wF,new $CLJS.P(null,1,5,$CLJS.Q,[a],null))};V3=new $CLJS.M("metabase.lib.aggregation","no-semantic-type","metabase.lib.aggregation/no-semantic-type",-789440092);
W3=new $CLJS.M("metabase.lib.aggregation","unary-aggregation","metabase.lib.aggregation/unary-aggregation",1064133592);X3=new $CLJS.M("metabase.lib.aggregation","count-aggregation","metabase.lib.aggregation/count-aggregation",-21314018);Y3=new $CLJS.M("metabase.lib.aggregation","quantity-aggregation","metabase.lib.aggregation/quantity-aggregation",411632918);Z3=new $CLJS.M("metabase.lib.aggregation","aggregation","metabase.lib.aggregation/aggregation",1701573815);
Fra=new $CLJS.M(null,"aggregation-operator","aggregation-operator",-1978700834);$CLJS.P_.m(null,$CLJS.$E,function(a,b){var c=$CLJS.Be($CLJS.$E.h($CLJS.DV(a,b)));return $CLJS.n(c)?$CLJS.L1($CLJS.JD("and"),function(){return function f(e){return new $CLJS.ie(null,function(){for(;;){var k=$CLJS.y(e);if(k){if($CLJS.wd(k)){var l=$CLJS.ic(k),m=$CLJS.D(l),t=$CLJS.le(m);a:for(var u=0;;)if(u<m){var v=$CLJS.id(l,u);v=$CLJS.l_.v(a,b,v,$CLJS.m_);t.add(v);u+=1}else{l=!0;break a}return l?$CLJS.oe($CLJS.qe(t),f($CLJS.jc(k))):$CLJS.oe($CLJS.qe(t),null)}t=$CLJS.z(k);return $CLJS.ae($CLJS.l_.v(a,
b,t,$CLJS.m_),f($CLJS.Hc(k)))}return null}},null,null)}(c)}()):null});$CLJS.S_.m(null,$CLJS.$E,function(a,b,c){$CLJS.I(c,0,null);var d=$CLJS.I(c,1,null),e=$CLJS.O(d);d=$CLJS.K.g(e,$CLJS.tA);e=$CLJS.K.g(e,$CLJS.mi);c=$CLJS.I(c,2,null);c=T3(a,b,c);return $CLJS.qk.l($CLJS.H([$CLJS.j_.j(a,b,c),new $CLJS.h(null,2,[$CLJS.LJ,$CLJS.iK,$CLJS.B0,$CLJS.yD.h($CLJS.dd(c))],null),$CLJS.n(d)?new $CLJS.h(null,1,[$CLJS.tA,d],null):null,$CLJS.n(e)?new $CLJS.h(null,1,[$CLJS.mi,e],null):null]))});
$CLJS.M_.m(null,$CLJS.$E,function(a,b,c,d){$CLJS.I(c,0,null);$CLJS.I(c,1,null);c=$CLJS.I(c,2,null);return $CLJS.l_.v(a,b,T3(a,b,c),d)});$CLJS.qE(X3,Z3);
for(var $3=$CLJS.y(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Rw,$CLJS.lF,$CLJS.tF],null)),a4=null,b4=0,c4=0;;)if(c4<b4){var Gra=a4.X(null,c4);$CLJS.qE(Gra,X3);c4+=1}else{var d4=$CLJS.y($3);if(d4){var e4=d4;if($CLJS.wd(e4)){var f4=$CLJS.ic(e4),Hra=$CLJS.jc(e4),Ira=f4,Jra=$CLJS.D(f4);$3=Hra;a4=Ira;b4=Jra}else{var Kra=$CLJS.z(e4);$CLJS.qE(Kra,X3);$3=$CLJS.B(e4);a4=null;b4=0}c4=0}else break}
$CLJS.M_.m(null,X3,function(a,b,c,d){var e=$CLJS.I(c,0,null);$CLJS.I(c,1,null);c=$CLJS.I(c,2,null);if($CLJS.n(c))switch(a=$CLJS.l_.v(a,b,c,d),e=e instanceof $CLJS.M?e.S:null,e){case "count":return $CLJS.hD("Count of {0}",$CLJS.H([a]));case "cum-count":return $CLJS.hD("Cumulative count of {0}",$CLJS.H([a]));default:throw Error(["No matching clause: ",$CLJS.p.h(e)].join(""));}else switch(e=e instanceof $CLJS.M?e.S:null,e){case "count":return $CLJS.JD("Count");case "cum-count":return $CLJS.JD("Cumulative count");
default:throw Error(["No matching clause: ",$CLJS.p.h(e)].join(""));}});$CLJS.N_.m(null,X3,function(a,b,c){a=$CLJS.I(c,0,null);a=a instanceof $CLJS.M?a.S:null;switch(a){case "count":return"count";case "cum-count":return"cum_count";case "count-where":return"count_where";default:throw Error(["No matching clause: ",$CLJS.p.h(a)].join(""));}});$CLJS.S_.m(null,Y3,function(a,b,c){var d=$CLJS.R.j,e=$CLJS.jH($CLJS.S_,Z3);a=e.j?e.j(a,b,c):e.call(null,a,b,c);return d.call($CLJS.R,a,$CLJS.nj,$CLJS.SB)});
$CLJS.qE(Y3,Z3);$CLJS.qE(X3,Y3);$CLJS.qE($CLJS.PE,Y3);$CLJS.M_.m(null,$CLJS.TE,function(){return $CLJS.JD("Case")});$CLJS.N_.m(null,$CLJS.TE,function(){return"case"});$CLJS.qE(W3,Z3);
for(var g4=$CLJS.y(new $CLJS.P(null,9,5,$CLJS.Q,[$CLJS.eF,$CLJS.FF,$CLJS.PE,$CLJS.wj,$CLJS.xF,$CLJS.pi,$CLJS.yF,$CLJS.wF,$CLJS.QE],null)),h4=null,i4=0,j4=0;;)if(j4<i4){var Lra=h4.X(null,j4);$CLJS.qE(Lra,W3);j4+=1}else{var k4=$CLJS.y(g4);if(k4){var l4=k4;if($CLJS.wd(l4)){var m4=$CLJS.ic(l4),Mra=$CLJS.jc(l4),Nra=m4,Ora=$CLJS.D(m4);g4=Mra;h4=Nra;i4=Ora}else{var Pra=$CLJS.z(l4);$CLJS.qE(Pra,W3);g4=$CLJS.B(l4);h4=null;i4=0}j4=0}else break}
$CLJS.N_.m(null,W3,function(a,b,c){a=$CLJS.I(c,0,null);$CLJS.I(c,1,null);$CLJS.I(c,2,null);c=a instanceof $CLJS.M?a.S:null;switch(c){case "avg":return"avg";case "cum-sum":return"sum";case "distinct":return"count";case "max":return"max";case "median":return"median";case "min":return"min";case "stddev":return"stddev";case "sum":return"sum";case "var":return"var";default:throw Error(["No matching clause: ",$CLJS.p.h(c)].join(""));}});
$CLJS.M_.m(null,W3,function(a,b,c,d){var e=$CLJS.I(c,0,null);$CLJS.I(c,1,null);c=$CLJS.I(c,2,null);a=$CLJS.l_.v(a,b,c,d);e=e instanceof $CLJS.M?e.S:null;switch(e){case "avg":return $CLJS.hD("Average of {0}",$CLJS.H([a]));case "cum-sum":return $CLJS.hD("Cumulative sum of {0}",$CLJS.H([a]));case "distinct":return $CLJS.hD("Distinct values of {0}",$CLJS.H([a]));case "max":return $CLJS.hD("Max of {0}",$CLJS.H([a]));case "median":return $CLJS.hD("Median of {0}",$CLJS.H([a]));case "min":return $CLJS.hD("Min of {0}",
$CLJS.H([a]));case "stddev":return $CLJS.hD("Standard deviation of {0}",$CLJS.H([a]));case "sum":return $CLJS.hD("Sum of {0}",$CLJS.H([a]));case "var":return $CLJS.hD("Variance of {0}",$CLJS.H([a]));default:throw Error(["No matching clause: ",$CLJS.p.h(e)].join(""));}});$CLJS.M_.m(null,$CLJS.WE,function(a,b,c,d){$CLJS.I(c,0,null);$CLJS.I(c,1,null);var e=$CLJS.I(c,2,null);c=$CLJS.I(c,3,null);return $CLJS.hD("{0}th percentile of {1}",$CLJS.H([c,$CLJS.l_.v(a,b,e,d)]))});$CLJS.N_.m(null,$CLJS.WE,function(){return"percentile"});
$CLJS.qE(V3,Z3);for(var n4=$CLJS.y(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.WE,$CLJS.QE],null)),o4=null,p4=0,q4=0;;)if(q4<p4){var Qra=o4.X(null,q4);$CLJS.qE(Qra,V3);q4+=1}else{var r4=$CLJS.y(n4);if(r4){var s4=r4;if($CLJS.wd(s4)){var t4=$CLJS.ic(s4),Rra=$CLJS.jc(s4),Sra=t4,Tra=$CLJS.D(t4);n4=Rra;o4=Sra;p4=Tra}else{var Ura=$CLJS.z(s4);$CLJS.qE(Ura,V3);n4=$CLJS.B(s4);o4=null;p4=0}q4=0}else break}
$CLJS.S_.m(null,V3,function(a,b,c){var d=$CLJS.Rj.g,e=$CLJS.jH($CLJS.S_,Z3);a=e.j?e.j(a,b,c):e.call(null,a,b,c);return d.call($CLJS.Rj,a,$CLJS.nj)});$CLJS.M_.m(null,$CLJS.DF,function(a,b,c,d){$CLJS.I(c,0,null);$CLJS.I(c,1,null);var e=$CLJS.I(c,2,null);$CLJS.I(c,3,null);return $CLJS.hD("Sum of {0} matching condition",$CLJS.H([$CLJS.l_.v(a,b,e,d)]))});
$CLJS.N_.m(null,$CLJS.DF,function(a,b,c){$CLJS.I(c,0,null);$CLJS.I(c,1,null);var d=$CLJS.I(c,2,null);$CLJS.I(c,3,null);return["sum_where_",$CLJS.p.h($CLJS.O_.j(a,b,d))].join("")});$CLJS.qE($CLJS.DF,Z3);$CLJS.M_.m(null,$CLJS.rF,function(){return $CLJS.JD("Share of rows matching condition")});$CLJS.N_.m(null,$CLJS.rF,function(){return"share"});$CLJS.S_.m(null,$CLJS.rF,function(a,b,c){var d=$CLJS.R.j,e=$CLJS.jH($CLJS.S_,Z3);a=e.j?e.j(a,b,c):e.call(null,a,b,c);return d.call($CLJS.R,a,$CLJS.nj,$CLJS.jC)});
$CLJS.qE($CLJS.rF,Z3);$CLJS.M_.m(null,$CLJS.tF,function(){return $CLJS.JD("Count of rows matching condition")});$CLJS.S_.m(null,Z3,function(a,b,c){$CLJS.I(c,0,null);$CLJS.I(c,1,null);var d=$CLJS.I(c,2,null),e=$CLJS.qk.l;d=$CLJS.n(d)?$CLJS.Bl($CLJS.j_.j(a,b,d),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ZJ,$CLJS.nj],null)):null;var f=$CLJS.jH($CLJS.S_,$CLJS.Oh);a=f.j?f.j(a,b,c):f.call(null,a,b,c);return e.call($CLJS.qk,$CLJS.H([d,a]))});
$CLJS.Vra=function(){function a(d){return $CLJS.I1($CLJS.Rw,new $CLJS.P(null,1,5,$CLJS.Q,[d],null))}function b(){return $CLJS.I1($CLJS.Rw,$CLJS.xf)}var c=null;c=function(d){switch(arguments.length){case 0:return b.call(this);case 1:return a.call(this,d)}throw Error("Invalid arity: "+arguments.length);};c.o=b;c.h=a;return c}();$CLJS.mV.m(null,$CLJS.$E,function(a){return a});
$CLJS.u4=function(){function a(d,e,f){for(;;)if($CLJS.E.g($CLJS.WA(f),$CLJS.PJ))f=$CLJS.oV(f);else return $CLJS.N1(d,e,$CLJS.$E,f)}function b(d,e){return c.j?c.j(d,-1,e):c.call(null,d,-1,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+arguments.length);};c.g=b;c.j=a;return c}();
$CLJS.v4=function(){function a(d,e){return $CLJS.Be($CLJS.$E.h($CLJS.DV(d,e)))}function b(d){return c.g?c.g(d,-1):c.call(null,d,-1)}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+arguments.length);};c.h=b;c.g=a;return c}();
$CLJS.w4=function(){function a(d,e){var f=$CLJS.Be($CLJS.$E.h($CLJS.DV(d,e)));return null==f?null:$CLJS.Wf.j($CLJS.xf,$CLJS.cf.h(function(k){var l=$CLJS.j_.j(d,e,k),m=$CLJS.R.l,t=$CLJS.tA.h(l);return m.call($CLJS.R,$CLJS.H1(l,$CLJS.mi,$CLJS.n(t)?t:$CLJS.Zi),$CLJS.LJ,$CLJS.iK,$CLJS.H([$CLJS.B0,$CLJS.yD.h($CLJS.dd(k))]))}),f)}function b(d){return c.g?c.g(d,-1):c.call(null,d,-1)}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+
arguments.length);};c.h=b;c.g=a;return c}();$CLJS.M_.m(null,$CLJS.RF,function(a,b,c){a=$CLJS.O(c);a=$CLJS.K.g(a,$CLJS.NF);return $CLJS.mD.h(a.o?a.o():a.call(null))});$CLJS.T_.m(null,$CLJS.RF,function(a,b,c){var d=$CLJS.O(c);a=$CLJS.K.g(d,$CLJS.dF);b=$CLJS.K.g(d,$CLJS.NF);c=$CLJS.K.g(d,$CLJS.MF);d=$CLJS.K.g(d,$CLJS.MY);a=$CLJS.R.l(b.o?b.o():b.call(null),$CLJS.K_,$CLJS.$z(a),$CLJS.H([$CLJS.I_,c]));return null!=d?$CLJS.R.j(a,$CLJS.t_,d):a});
$CLJS.Wra=function(){function a(d,e){var f=function(){var m=$CLJS.dz.h($CLJS.Z0(d));return $CLJS.n(m)?m:$CLJS.Sg}(),k=$CLJS.DV(d,e),l=$CLJS.Y_.j(d,e,k);return $CLJS.Be($CLJS.Wf.j($CLJS.xf,$CLJS.Yj.j($CLJS.ef(function(m){m=$CLJS.PF.h(m);var t=null==m;return t?t:f.h?f.h(m):f.call(null,m)}),$CLJS.tl(function(m){m=$CLJS.O(m);var t=$CLJS.K.g(m,$CLJS.MF),u=$CLJS.K.g(m,$CLJS.KF);if($CLJS.Ra(t))return m;if($CLJS.E.g(u,$CLJS.ur))return $CLJS.R.j(m,$CLJS.AV,l);t=$CLJS.Be($CLJS.y0(function(v){return $CLJS.CZ(u,
v)},l));return $CLJS.n(t)?$CLJS.R.j(m,$CLJS.AV,t):null}),$CLJS.cf.h(function(m){return $CLJS.R.j(m,$CLJS.Vs,$CLJS.RF)})),$CLJS.SF))}function b(d){return c.g?c.g(d,-1):c.call(null,d,-1)}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+arguments.length);};c.h=b;c.g=a;return c}();
$CLJS.Xra=function(){function a(d,e){return $CLJS.nV(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.dF.h(d),$CLJS.N,$CLJS.pV.h(e)],null))}function b(d){if($CLJS.Ra($CLJS.MF.h(d)))return $CLJS.nV(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.dF.h(d),$CLJS.N],null));var e=$CLJS.dF.h(d);e=$CLJS.ha.g?$CLJS.ha.g("aggregation operator %s requires an argument",e):$CLJS.ha.call(null,"aggregation operator %s requires an argument",e);throw $CLJS.Uh(e,new $CLJS.h(null,1,[Fra,d],null));}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,
d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+arguments.length);};c.h=b;c.g=a;return c}();
var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.set.js");require("./clojure.walk.js");require("./malli.core.js");require("./metabase.lib.common.js");require("./metabase.lib.equality.js");require("./metabase.lib.expression.js");require("./metabase.lib.join.js");require("./metabase.lib.join.util.js");require("./metabase.lib.metadata.calculation.js");require("./metabase.lib.options.js");require("./metabase.lib.ref.js");require("./metabase.lib.util.js");require("./cljs.core.match.js");require("./metabase.util.js");require("./metabase.util.malli.js");
'use strict';var r5,Fsa,Gsa,Hsa,t5,w5,x5,y5,Isa,Jsa,B5,z5,A5,E5,F5,Ksa,Lsa,G5,Msa,Nsa,Osa,Psa,Qsa,Rsa,Ssa,I5,Tsa,Usa,Vsa,J5,Wsa,Xsa,K5,Ysa,Zsa,$sa,ata;r5=function(a,b){if(b=$CLJS.y(b)){var c=$CLJS.y(b);b=$CLJS.z(c);c=$CLJS.B(c);return $CLJS.y(c)?(c=r5($CLJS.K.g(a,b),c),$CLJS.pd(c)?$CLJS.Rj.g(a,b):$CLJS.R.j(a,b,c)):$CLJS.Rj.g(a,b)}return a};
Fsa=function(a,b,c,d){return function t(f,k,l,m){var u=$CLJS.y(k);k=$CLJS.z(u);u=$CLJS.B(u);var v=$CLJS.Cl(f,k);return $CLJS.n(v)?u?$CLJS.R.j(f,k,t($CLJS.Cb(v),u,l,m)):$CLJS.R.j(f,k,$CLJS.Ne.j(l,$CLJS.Cb(v),m)):f}(a,b,c,d)};Gsa=function(a){for(var b=[],c=arguments.length,d=0;;)if(d<c)b.push(arguments[d]),d+=1;else break;return Fsa(arguments[0],arguments[1],arguments[2],3<b.length?new $CLJS.w(b.slice(3),0,null):null)};$CLJS.s5=function(a){return $CLJS.yD.h($CLJS.FD(a))};
Hsa=function(a,b,c,d){var e=$CLJS.E.g($CLJS.YO,$CLJS.z(b))?$CLJS.xV(d,function(){var f=$CLJS.NV.h(d);return $CLJS.n(f)?f:$CLJS.wV(c)}()):d;return Gsa(a,b,function(f){return $CLJS.yf(function(){return function m(l){return new $CLJS.ie(null,function(){for(;;){var t=$CLJS.y(l);if(t){if($CLJS.wd(t)){var u=$CLJS.ic(t),v=$CLJS.D(u),x=$CLJS.le(v);a:for(var A=0;;)if(A<v){var C=$CLJS.id(u,A);C=$CLJS.E.g($CLJS.s5(C),$CLJS.s5(c))?e:C;x.add(C);A+=1}else{u=!0;break a}return u?$CLJS.oe($CLJS.qe(x),m($CLJS.jc(t))):
$CLJS.oe($CLJS.qe(x),null)}x=$CLJS.z(t);return $CLJS.ae($CLJS.E.g($CLJS.s5(x),$CLJS.s5(c))?e:x,m($CLJS.Hc(t)))}return null}},null,null)}(f)}())})};
t5=function(a,b,c,d){var e=$CLJS.Bz(a,b);if($CLJS.n(e)){var f=$CLJS.s5(c),k=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.z(b),$CLJS.fd(b)],null);c=$CLJS.I(k,0,null);k=$CLJS.I(k,1,null);e=$CLJS.Wf.j($CLJS.xf,$CLJS.Zj.h($CLJS.Yj.g($CLJS.Tg([f]),$CLJS.s5)),e);e=$CLJS.E.g(b,new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.SD],null))&&$CLJS.Le(function(l){return $CLJS.J1(l,$CLJS.qA)},e)?null:e;if($CLJS.y(e))return $CLJS.VK(a,b,e);if($CLJS.E.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.CM,$CLJS.sK],null),new $CLJS.P(null,2,5,$CLJS.Q,
[c,k],null)))throw $CLJS.Uh($CLJS.JD("Cannot remove the final join condition"),new $CLJS.h(null,5,[$CLJS.Cx,$CLJS.IV,$CLJS.sK,$CLJS.Bz(a,b),$CLJS.JV,$CLJS.Bz(a,$CLJS.od(b)),$CLJS.DK,d,$CLJS.KV,a],null));return $CLJS.E.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.CM,$CLJS.SD],null),new $CLJS.P(null,2,5,$CLJS.Q,[c,k],null))?$CLJS.uW.v(a,$CLJS.od(b),$CLJS.Rj,k):r5(a,b)}return a};$CLJS.u5=function(a,b){a=$CLJS.O(a);a=$CLJS.K.g(a,$CLJS.pK);b=0>b?$CLJS.D(a)+b:b;return b+1<$CLJS.D(a)?b+1:null};
$CLJS.v5=function(a,b){a=$CLJS.OV.l(a,b,$CLJS.Rj,$CLJS.H([$CLJS.SD]));return $CLJS.Wf.j($CLJS.xf,$CLJS.Zj.h($CLJS.Yj.g(new $CLJS.Rg(null,new $CLJS.h(null,2,[$CLJS.HY,null,$CLJS.GY,null],null),null),$CLJS.LJ)),$CLJS.W_.j(a,b,$CLJS.DV(a,b)))};
w5=function(a,b,c,d){var e=$CLJS.S1.g($CLJS.D(c),$CLJS.D(d));return $CLJS.n(e)?(c=$CLJS.pG(function(f){return $CLJS.W1.v(a,b,f,d)},c),(e=!$CLJS.Dd(c,null))?(e=$CLJS.S1.g($CLJS.D(c),$CLJS.D(d)),$CLJS.n(e)?$CLJS.Le(function(f){return $CLJS.S1.g($CLJS.D(f),1)},$CLJS.Pg(c)):e):e):e};
x5=function(a,b){var c=$CLJS.G3.g(a,b),d=$CLJS.bh(0,$CLJS.D(c));return $CLJS.bf.l(new $CLJS.P(null,6,5,$CLJS.Q,[new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.MQ],null),new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.ZE],null),new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.gV],null),new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.SD],null),new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.$E],null),new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.YO],null)],null),function(){return function k(f){return new $CLJS.ie(null,function(){for(var l=f;;)if(l=$CLJS.y(l)){if($CLJS.wd(l)){var m=
$CLJS.ic(l),t=$CLJS.D(m),u=$CLJS.le(t);a:for(var v=0;;)if(v<t){var x=$CLJS.id(m,v),A=$CLJS.F(c,x);$CLJS.SD.h(A)instanceof $CLJS.M||u.add(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.CM,x,$CLJS.SD],null));v+=1}else{m=!0;break a}return m?$CLJS.oe($CLJS.qe(u),k($CLJS.jc(l))):$CLJS.oe($CLJS.qe(u),null)}u=$CLJS.z(l);m=$CLJS.F(c,u);if($CLJS.SD.h(m)instanceof $CLJS.M)l=$CLJS.Hc(l);else return $CLJS.ae(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.CM,u,$CLJS.SD],null),k($CLJS.Hc(l)))}else return null},null,null)}(d)}(),$CLJS.H([function(){return function k(f){return new $CLJS.ie(null,
function(){for(;;){var l=$CLJS.y(f);if(l){if($CLJS.wd(l)){var m=$CLJS.ic(l),t=$CLJS.D(m),u=$CLJS.le(t);a:for(var v=0;;)if(v<t){var x=$CLJS.id(m,v);u.add(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.CM,x,$CLJS.sK],null));v+=1}else{m=!0;break a}return m?$CLJS.oe($CLJS.qe(u),k($CLJS.jc(l))):$CLJS.oe($CLJS.qe(u),null)}u=$CLJS.z(l);return $CLJS.ae(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.CM,u,$CLJS.sK],null),k($CLJS.Hc(l)))}return null}},null,null)}(d)}()]))};
y5=function(a,b,c){var d=$CLJS.I(c,0,null),e=$CLJS.I(c,1,null);e=$CLJS.O(e);var f=$CLJS.K.g(e,$CLJS.qF),k=$CLJS.K.g(e,$CLJS.sQ),l=$CLJS.I(c,2,null);return $CLJS.z($CLJS.qG(function(m){$CLJS.I(m,0,null);m=$CLJS.I(m,1,null);$CLJS.I(m,0,null);$CLJS.I(m,1,null);m=$CLJS.I(m,2,null);return $CLJS.E.g($CLJS.z(m),d)&&$CLJS.E.g($CLJS.qF.h($CLJS.dd(m)),f)&&$CLJS.E.g($CLJS.sQ.h($CLJS.dd(m)),k)&&$CLJS.E.g($CLJS.fd(m),l)},$CLJS.Al($CLJS.xr,$CLJS.MQ.h($CLJS.DV(a,b)))))};
Isa=function(a,b,c,d){c=y5(a,b,c);return $CLJS.n(c)?$CLJS.OV.l(a,b,$CLJS.uW,$CLJS.H([new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.MQ,c,2,1],null),$CLJS.Yj.g(function(e){return $CLJS.V0($CLJS.Xk,e)},$CLJS.qk),d])):a};Jsa=function(a,b,c){c=y5(a,b,c);return $CLJS.n(c)?$CLJS.OV.l(a,b,t5,$CLJS.H([new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.MQ],null),$CLJS.Bz($CLJS.DV(a,b),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.MQ,c],null)),b])):a};
B5=function(a,b,c,d,e,f){var k=$CLJS.OV.l(a,b,f,$CLJS.H([d,e])),l=$CLJS.s5(e);return $CLJS.Xj.g(a,k)?$CLJS.z($CLJS.y($CLJS.ff($CLJS.Sa,function(m,t){try{if($CLJS.vd(t)&&1===$CLJS.D(t))try{var u=$CLJS.F(t,0);if($CLJS.ce(u,$CLJS.YO))return new $CLJS.P(null,1,5,$CLJS.Q,[function(){var J=$CLJS.wV(e);J=z5.W?z5.W(k,b,c,$CLJS.qA,$CLJS.N,J):z5.call(null,k,b,c,$CLJS.qA,$CLJS.N,J);return A5.v?A5.v(J,b,c,l):A5.call(null,J,b,c,l)}()],null);throw $CLJS.Z;}catch(J){if(J instanceof Error)if(m=J,m===$CLJS.Z)try{u=
$CLJS.F(t,0);if($CLJS.ce(u,$CLJS.$E))return new $CLJS.P(null,1,5,$CLJS.Q,[function(){var S=z5.W?z5.W(k,b,c,$CLJS.$E,$CLJS.N,l):z5.call(null,k,b,c,$CLJS.$E,$CLJS.N,l);return A5.v?A5.v(S,b,c,l):A5.call(null,S,b,c,l)}()],null);throw $CLJS.Z;}catch(S){if(S instanceof Error){var v=S;if(v===$CLJS.Z)throw $CLJS.Z;throw v;}throw S;}else throw m;else throw J;}else throw $CLJS.Z;}catch(J){if(J instanceof Error)if(m=J,m===$CLJS.Z)try{if($CLJS.vd(t)&&1===$CLJS.D(t))try{var x=$CLJS.F(t,0);if($CLJS.ce(x,$CLJS.ZE))return new $CLJS.P(null,
1,5,$CLJS.Q,[A5.v?A5.v(k,b,c,l):A5.call(null,k,b,c,l)],null);throw $CLJS.Z;}catch(S){if(S instanceof Error)if(v=S,v===$CLJS.Z)try{x=$CLJS.F(t,0);if($CLJS.ce(x,$CLJS.SD))return new $CLJS.P(null,1,5,$CLJS.Q,[A5.v?A5.v(k,b,c,l):A5.call(null,k,b,c,l)],null);throw $CLJS.Z;}catch(X){if(X instanceof Error){var A=X;if(A===$CLJS.Z)throw $CLJS.Z;throw A;}throw X;}else throw v;else throw S;}else throw $CLJS.Z;}catch(S){if(S instanceof Error)if(v=S,v===$CLJS.Z)try{if($CLJS.vd(t)&&3===$CLJS.D(t))try{var C=$CLJS.F(t,
0);if($CLJS.ce(C,$CLJS.CM))try{var G=$CLJS.F(t,2);if($CLJS.ce(G,$CLJS.SD))return new $CLJS.P(null,1,5,$CLJS.Q,[A5.v?A5.v(k,b,c,l):A5.call(null,k,b,c,l)],null);throw $CLJS.Z;}catch(X){if(X instanceof Error){A=X;if(A===$CLJS.Z)throw $CLJS.Z;throw A;}throw X;}else throw $CLJS.Z;}catch(X){if(X instanceof Error){A=X;if(A===$CLJS.Z)throw $CLJS.Z;throw A;}throw X;}else throw $CLJS.Z;}catch(X){if(X instanceof Error){A=X;if(A===$CLJS.Z)return new $CLJS.P(null,1,5,$CLJS.Q,[k],null);throw A;}throw X;}else throw v;
else throw S;}else throw m;else throw J;}}($CLJS.xf,d)))):k};
z5=function(a,b,c,d,e,f){var k=$CLJS.DV(a,b),l=$CLJS.df(function(t){var u=$CLJS.Bz(k,t);return $CLJS.n(u)?$CLJS.zl(function(v){return $CLJS.z($CLJS.y($CLJS.ff($CLJS.Sa,function G(A,C){try{if($CLJS.vd(C)&&3===$CLJS.D(C))try{var J=$CLJS.F(C,0);if($CLJS.E.g(J,d))try{var S=$CLJS.F(C,1);if($CLJS.pd(e)||$CLJS.U0($CLJS.Wg(e),$CLJS.Wg(S)))try{var X=$CLJS.F(C,2);if($CLJS.E.g(X,f))return new $CLJS.P(null,1,5,$CLJS.Q,[new $CLJS.P(null,2,5,$CLJS.Q,[t,v],null)],null);throw $CLJS.Z;}catch(da){if(da instanceof Error){var W=
da;if(W===$CLJS.Z)throw $CLJS.Z;throw W;}throw da;}else throw $CLJS.Z;}catch(da){if(da instanceof Error){W=da;if(W===$CLJS.Z)throw $CLJS.Z;throw W;}throw da;}else throw $CLJS.Z;}catch(da){if(da instanceof Error){W=da;if(W===$CLJS.Z)throw $CLJS.Z;throw W;}throw da;}else throw $CLJS.Z;}catch(da){if(da instanceof Error){W=da;if(W===$CLJS.Z)return $CLJS.yU(G,A,C);throw W;}throw da;}}($CLJS.xf,v))))},u):null},$CLJS.H([x5(a,b)])),m=$CLJS.Ve($CLJS.ac($CLJS.xf));a=$CLJS.cb(function(t,u){var v=$CLJS.I(u,0,
null);u=$CLJS.I(u,1,null);return B5(t,b,c,v,u,function(x,A,C){try{return t5(x,A,C,b)}catch(J){if(J instanceof Error){A=J;var G=$CLJS.O($CLJS.El(A));C=$CLJS.K.g(G,$CLJS.Cx);G=$CLJS.K.g(G,$CLJS.JV);if($CLJS.E.g(C,$CLJS.IV))return m.nd(null,$CLJS.te(m.Lb(null),G)),x;throw A;}throw J;}})},a,l);return $CLJS.cb(function(t,u){return $CLJS.C5.j?$CLJS.C5.j(t,b,u):$CLJS.C5.call(null,t,b,u)},a,$CLJS.cc($CLJS.q(m)))};
A5=function(a,b,c,d){b=$CLJS.u5(c,b);if($CLJS.n(b)){var e=$CLJS.DV(c,b);c=$CLJS.Me(function(f){f=$CLJS.O(f);var k=$CLJS.K.g(f,$CLJS.LJ),l=$CLJS.K.g(f,$CLJS.B0);return $CLJS.E.g($CLJS.vY,k)&&$CLJS.E.g(d,l)?$CLJS.KY.h(f):null},$CLJS.Y_.j(c,b,e));return $CLJS.n(c)?z5(a,b,a,$CLJS.kF,$CLJS.N,c):a}return a};
E5=function(a,b,c,d,e){var f=$CLJS.pV.h(c),k=$CLJS.DV(a,b);c=$CLJS.qG(function(t){var u=$CLJS.Bz(k,t);if($CLJS.n(u)){var v=$CLJS.s5(f);return $CLJS.n($CLJS.Me($CLJS.Yj.j($CLJS.Tg([v]),$CLJS.yD,$CLJS.dd),u))?t:null}return null},x5(a,b));var l=(d=$CLJS.E.g($CLJS.HF,d))?$CLJS.pV.h(e):null;e=d?function(t,u,v){return Hsa(t,u,v,l)}:function(t,u,v){return t5(t,u,v,b)};var m=$CLJS.E.g(new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.ZE],null),c);a=d&&m&&$CLJS.E.g($CLJS.z(f),$CLJS.z(l))&&$CLJS.E.g($CLJS.fd(f),$CLJS.fd(l))?
Isa(a,b,f,$CLJS.Bl($CLJS.dd(l),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.sQ,$CLJS.qF],null))):m?Jsa(a,b,f):a;return $CLJS.n(c)?(a=B5(a,b,a,c,f,e),$CLJS.D5.h?$CLJS.D5.h(a):$CLJS.D5.call(null,a)):a};F5=function(a){return $CLJS.GD(a,$CLJS.R,$CLJS.H([$CLJS.yD,$CLJS.p.h($CLJS.ID())]))};
Ksa=function(a,b,c){return function(d){return function l(f,k){try{if($CLJS.vd(k)&&3===$CLJS.D(k))try{var m=$CLJS.F(k,0);if($CLJS.ce(m,$CLJS.qA))try{var t=$CLJS.F(k,2);if($CLJS.E.g(t,b))return F5(c);throw $CLJS.Z;}catch(v){if(v instanceof Error){var u=v;if(u===$CLJS.Z)throw $CLJS.Z;throw u;}throw v;}else throw $CLJS.Z;}catch(v){if(v instanceof Error){u=v;if(u===$CLJS.Z)throw $CLJS.Z;throw u;}throw v;}else throw $CLJS.Z;}catch(v){if(v instanceof Error){u=v;if(u===$CLJS.Z)return $CLJS.WV(l,f,k);throw u;
}throw v;}}($CLJS.xf,d)}(a)};Lsa=function(a,b,c){var d=function(){var l=$CLJS.wV(c);return $CLJS.n(l)?l:$CLJS.T.h($CLJS.FD(c))}(),e=F5($CLJS.xV(c,d));a=$CLJS.bk.j(a,$CLJS.YO,function(l){return $CLJS.ak.g(function(m){return $CLJS.E.g(m,b)?e:m},l)});var f=$CLJS.wV(b),k=$CLJS.mi.h($CLJS.FD(c));return Ksa(a,f,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.qA,new $CLJS.h(null,1,[$CLJS.mi,k],null),d],null))};
G5=function(a,b,c){return $CLJS.sU(function(d){return $CLJS.E.g(d,$CLJS.s5(b))?$CLJS.s5(c):d},$CLJS.n($CLJS.wV(b))?Lsa(a,b,c):$CLJS.sU(function(d){return $CLJS.E.g(d,b)?c:d},a))};Msa=function(a,b,c){a=$CLJS.W_.j(a,b,$CLJS.DV(a,b));b=$CLJS.W_.j(c,b,$CLJS.DV(c,b));return $CLJS.ff(function(d){return $CLJS.Xj.g($CLJS.z(d),$CLJS.dd(d))},$CLJS.cf.j($CLJS.xr,a,b))};
Nsa=function(a,b,c){var d=$CLJS.I(c,0,null);c=$CLJS.I(c,1,null);var e=$CLJS.KY.h(d),f=$CLJS.oV($CLJS.R.j(c,$CLJS.LJ,$CLJS.vY));return $CLJS.cf.g(function(k){return new $CLJS.P(null,2,5,$CLJS.Q,[k,F5(f)],null)},$CLJS.y($CLJS.ff($CLJS.Sa,function t(l,m){try{if($CLJS.vd(m)&&3===$CLJS.D(m))try{var u=$CLJS.F(m,0);if($CLJS.ce(u,$CLJS.kF))try{var v=$CLJS.F(m,2);if($CLJS.E.g(v,e))return new $CLJS.P(null,1,5,$CLJS.Q,[m],null);throw $CLJS.Z;}catch(A){if(A instanceof Error){var x=A;if(x===$CLJS.Z)throw $CLJS.Z;
throw x;}throw A;}else throw $CLJS.Z;}catch(A){if(A instanceof Error){x=A;if(x===$CLJS.Z)throw $CLJS.Z;throw x;}throw A;}else throw $CLJS.Z;}catch(A){if(A instanceof Error){x=A;if(x===$CLJS.Z)return $CLJS.yU(t,l,m);throw x;}throw A;}}($CLJS.xf,$CLJS.DV(a,b)))))};Osa=function(a,b,c){var d=$CLJS.mi.h($CLJS.FD(c));$CLJS.n(d)||(d=$CLJS.N0.h?$CLJS.N0.h(c):$CLJS.N0.call(null,c),d=$CLJS.Ra(d)||!$CLJS.vV(c));if($CLJS.n(d))return c;a=$CLJS.R_.j(a,b,c);return $CLJS.GD(c,$CLJS.R,$CLJS.H([$CLJS.mi,a]))};
Psa=function(a,b){a=$CLJS.wV(a);return $CLJS.n($CLJS.n(a)?$CLJS.Ra($CLJS.wV(b))&&$CLJS.Ra($CLJS.T.h($CLJS.FD(b))):a)?$CLJS.xV(b,a):b};
Qsa=function(a,b,c,d){d=Osa(a,b,Psa(c,d));c=$CLJS.OV.l(a,b,G5,$CLJS.H([c,d]));for(var e=b;;){var f=$CLJS.u5(c,e);if($CLJS.n(f)){b=f;var k=$CLJS.df(function(l,m,t){return function(u){return Nsa(l,t,u)}}(c,e,b,f,a,d),$CLJS.H([Msa(a,e,c)]));c=$CLJS.cb(function(l,m,t,u){return function(v,x){var A=$CLJS.I(x,0,null);x=$CLJS.I(x,1,null);return $CLJS.OV.l(v,u,G5,$CLJS.H([A,x]))}}(c,e,k,b,f,a,d),c,k);e=b}else return c}};
Rsa=function(a,b){b=$CLJS.Lk.h(b);if($CLJS.E.g($CLJS.z(b),$CLJS.pK)){var c=$CLJS.dd(b),d=$CLJS.B($CLJS.B(b)),e=function(){var f=$CLJS.B(d);return f?$CLJS.Me(function(k){return $CLJS.Le($CLJS.hl,$CLJS.cf.j($CLJS.E,k,d))?k:null},x5(a,c)):f}();return $CLJS.n(e)?$CLJS.fk.j(b,0,$CLJS.D(e)+2+1):null}return null};
Ssa=function(a,b,c,d){for(var e=Qsa(a,b,c,d);;){var f=$CLJS.EX($CLJS.JK,e),k=$CLJS.rk.h($CLJS.zl(function(l){return function(m){return Rsa(l,m)}}(e,f),$CLJS.Hr.h(f)));if($CLJS.y(k))e=$CLJS.cb(function(){return function(l,m){try{return $CLJS.H5.j(l,$CLJS.dd(m),$CLJS.Bz(l,m))}catch(x){if(x instanceof Error){var t=x,u=$CLJS.O($CLJS.El(t)),v=$CLJS.K.g(u,$CLJS.Cx);u=$CLJS.K.g(u,$CLJS.JV);if($CLJS.E.g(v,$CLJS.IV))return m=$CLJS.dd(m),$CLJS.C5.j?$CLJS.C5.j(l,m,u):$CLJS.C5.call(null,l,m,u);throw t;}throw x;
}}}(e,f,k),e,k);else return $CLJS.n(f)?E5(a,b,c,$CLJS.HF,d):e}};I5=function(a,b){return $CLJS.J1(a,$CLJS.kF)&&$CLJS.E.g($CLJS.k1(a),b)};Tsa=function(a,b,c){return function k(e,f){try{if(I5(f,b))return $CLJS.l3(f,c);throw $CLJS.Z;}catch(m){if(m instanceof Error){var l=m;if(l===$CLJS.Z)return $CLJS.WV(k,e,f);throw l;}throw m;}}($CLJS.xf,a)};
Usa=function(a,b,c){var d=$CLJS.CM.h(a),e=-1<b&&b<$CLJS.D(d)?new $CLJS.P(null,2,5,$CLJS.Q,[b,$CLJS.Bz(d,new $CLJS.P(null,2,5,$CLJS.Q,[b,$CLJS.WD],null))],null):null;b=$CLJS.I(e,0,null);e=$CLJS.I(e,1,null);if($CLJS.n($CLJS.n(b)?$CLJS.Xj.g(e,c):b)){var f=$CLJS.GV();$CLJS.g3(f,$CLJS.cf.g($CLJS.WD,d));c=f(c);return Tsa($CLJS.VK(a,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.CM,b,$CLJS.WD],null),c),e,c)}return a};
Vsa=function(a,b,c){if($CLJS.Bd(c))return c;var d=function(){var e=$CLJS.Tg([c]);return"string"===typeof c?$CLJS.Yj.g(e,$CLJS.WD):e}();return $CLJS.Me(function(e){var f=$CLJS.I(e,0,null);e=$CLJS.I(e,1,null);return $CLJS.n(d.h?d.h(e):d.call(null,e))?f:null},$CLJS.Al($CLJS.xr,$CLJS.CM.h($CLJS.DV(a,b))))};
J5=function(a,b,c,d){b=$CLJS.bt.g($CLJS.Wg($CLJS.Y_.j(b,c,$CLJS.DV(b,c))),$CLJS.Wg($CLJS.Y_.j(a,c,$CLJS.DV(a,c))));return $CLJS.cb(function(e,f){return $CLJS.Ne.M(z5,e,c,a,d.h?d.h(f):d.call(null,f))},a,b)};Wsa=function(a,b,c){a=J5(a,b,c,function(d){return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.kF,new $CLJS.h(null,1,[$CLJS.sO,$CLJS.eZ.h(d)],null),$CLJS.Ei.h(d)],null)});c=$CLJS.u5(a,c);return $CLJS.n(c)?J5(a,b,c,function(d){return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.kF,$CLJS.N,$CLJS.KY.h(d)],null)}):a};
Xsa=function(a,b,c){return $CLJS.Bd(c)?$CLJS.Bz($CLJS.DV(a,b),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.CM,c,$CLJS.WD],null)):$CLJS.td(c)?$CLJS.WD.h(c):c};
K5=function(a,b,c,d){var e=Xsa(a,b,c);return $CLJS.n(e)?(c=function(){var f=(0,$CLJS.OV)(a,b,function(k){var l=$CLJS.CM.h(k);l=d.g?d.g(l,e):d.call(null,l,e);return $CLJS.ED(k,$CLJS.CM,l)});return(0,$CLJS.OV)(f,b,function(k){return $CLJS.RV(k,$CLJS.CM,function(l){return $CLJS.ak.g(function(m){return $CLJS.t3(f,b,m)},l)})})}(),c=Wsa(c,a,b),$CLJS.D5.h?$CLJS.D5.h(c):$CLJS.D5.call(null,c)):a};
Ysa=function(a,b){return null!=$CLJS.z($CLJS.y($CLJS.ff($CLJS.Sa,function f(d,e){try{if(I5(e,b))return new $CLJS.P(null,1,5,$CLJS.Q,[e],null);throw $CLJS.Z;}catch(l){if(l instanceof Error){var k=l;if(k===$CLJS.Z)return $CLJS.yU(f,d,e);throw k;}throw l;}}($CLJS.xf,a))))};Zsa=function(a,b){var c=$CLJS.SD.h($CLJS.DV(a,b));if($CLJS.n(c)){var d=$CLJS.Ra($CLJS.Me($CLJS.Yj.g($CLJS.DM,$CLJS.FD),c));return d?w5(a,b,c,$CLJS.v5(a,b)):d}return c};
$sa=function(a,b){var c=$CLJS.DV(a,b),d=$CLJS.n(Zsa(a,b))?$CLJS.OV.l(a,b,$CLJS.Rj,$CLJS.H([$CLJS.SD])):a;return $CLJS.n($CLJS.CM.h(c))?$CLJS.OV.l(d,b,$CLJS.bk,$CLJS.H([$CLJS.CM,$CLJS.Qe($CLJS.ak,function(e){var f=$CLJS.SD.h(e);var k=new $CLJS.Rg(null,new $CLJS.h(null,2,[$CLJS.wx,null,$CLJS.cz,null],null),null);f=k.h?k.h(f):k.call(null,f);return $CLJS.n(f)?e:$CLJS.n(w5(a,b,$CLJS.SD.h(e),$CLJS.W_.j(a,b,$CLJS.R.j(e,$CLJS.SD,$CLJS.wx))))?$CLJS.R.j(e,$CLJS.SD,$CLJS.wx):e})])):d};
$CLJS.D5=function(a){return $CLJS.cb($sa,a,$CLJS.bh(0,$CLJS.D($CLJS.pK.h(a))))};ata=new $CLJS.M(null,"remove","remove",-131428414);$CLJS.L5=new $CLJS.M("lib","hide-bin-bucket?","lib/hide-bin-bucket?",-701033945);var M5;$CLJS.H5=function(){function a(d,e,f){return $CLJS.td(f)&&$CLJS.E.g($CLJS.Vs.h(f),$CLJS.kK)?$CLJS.C5.j?$CLJS.C5.j(d,e,f):$CLJS.C5.call(null,d,e,f):E5(d,e,f,ata,null)}function b(d,e){return c.j?c.j(d,-1,e):c.call(null,d,-1,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+arguments.length);};c.g=b;c.j=a;return c}();M5=$CLJS.nE.h($CLJS.YD);
$CLJS.O5=function(){function a(d,e,f,k){if($CLJS.td(f)&&$CLJS.E.g($CLJS.Vs.h(f),$CLJS.kK))d=$CLJS.N5.v?$CLJS.N5.v(d,e,f,k):$CLJS.N5.call(null,d,e,f,k);else{var l=M5.h?M5.h(f):M5.call(null,f);l=$CLJS.n(l)?M5.h?M5.h(k):M5.call(null,k):l;d=$CLJS.n(l)?Ssa(d,e,f,k):E5(d,e,f,$CLJS.HF,k)}return d}function b(d,e,f){return c.v?c.v(d,-1,e,f):c.call(null,d,-1,e,f)}var c=null;c=function(d,e,f,k){switch(arguments.length){case 3:return b.call(this,d,e,f);case 4:return a.call(this,d,e,f,k)}throw Error("Invalid arity: "+
arguments.length);};c.j=b;c.v=a;return c}();$CLJS.bta=function(){function a(d,e,f,k){f=Vsa(d,e,f);return $CLJS.n(f)?$CLJS.OV.l(d,e,Usa,$CLJS.H([f,k])):d}function b(d,e,f){return c.v?c.v(d,-1,e,f):c.call(null,d,-1,e,f)}var c=null;c=function(d,e,f,k){switch(arguments.length){case 3:return b.call(this,d,e,f);case 4:return a.call(this,d,e,f,k)}throw Error("Invalid arity: "+arguments.length);};c.j=b;c.v=a;return c}();
$CLJS.C5=function(){function a(d,e,f){try{return K5(d,e,f,function(u,v){return $CLJS.Be($CLJS.y0(function(x){return!($CLJS.E.g($CLJS.WD.h(x),v)||Ysa(x,v))},u))})}catch(u){var k=u,l=$CLJS.O($CLJS.El(k)),m=$CLJS.K.g(l,$CLJS.DK),t=$CLJS.K.g(l,$CLJS.Cx);l=$CLJS.K.g(l,$CLJS.JV);if($CLJS.E.g(t,$CLJS.IV))return d=c.j?c.j(d,m,l):c.call(null,d,m,l),c.j?c.j(d,e,f):c.call(null,d,e,f);throw k;}}function b(d,e){return c.j?c.j(d,-1,e):c.call(null,d,-1,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,
d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+arguments.length);};c.g=b;c.j=a;return c}();
$CLJS.N5=function(){function a(d,e,f,k){return null==k?$CLJS.C5.j(d,e,f):K5(d,e,f,function(l,m){return $CLJS.ak.g(function(t){return $CLJS.E.g($CLJS.WD.h(t),m)?k:t},l)})}function b(d,e,f){return c.v?c.v(d,-1,e,f):c.call(null,d,-1,e,f)}var c=null;c=function(d,e,f,k){switch(arguments.length){case 3:return b.call(this,d,e,f);case 4:return a.call(this,d,e,f,k)}throw Error("Invalid arity: "+arguments.length);};c.j=b;c.v=a;return c}();
var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./metabase.lib.convert.js");require("./metabase.lib.metadata.js");require("./metabase.lib.metadata.calculation.js");require("./metabase.lib.query.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.id.js");require("./metabase.lib.schema.metadata.js");require("./metabase.lib.util.js");require("./shadow.js.shim.module$ttag.js");require("./clojure.string.js");require("./metabase.util.js");require("./metabase.util.humanization.js");require("./metabase.util.malli.js");
'use strict';$CLJS.j1=function(a,b){var c=$CLJS.EV(b);return $CLJS.n(c)?$CLJS.f_(a,c):$CLJS.d_(a,b)};$CLJS.k1=function(a){var b=$CLJS.WA(a);b=b instanceof $CLJS.M?b.S:null;switch(b){case "dispatch-type/nil":return null;case "field":return $CLJS.sO.h($CLJS.FD(a));case "metadata/column":return $CLJS.eZ.h(a);case "mbql/join":return $CLJS.WD.h(a);default:throw Error(["No matching clause: ",$CLJS.p.h(b)].join(""));}};
$CLJS.l1=function(a,b){return $CLJS.ha.j?$CLJS.ha.j("%s__%s",a,b):$CLJS.ha.call(null,"%s__%s",a,b)};$CLJS.m1=function(a,b){var c=$CLJS.k1(b);if($CLJS.n(c))a=c;else a:{var d=$CLJS.O(b);c=$CLJS.K.g(d,$CLJS.XJ);d=$CLJS.K.g(d,$CLJS.YJ);if($CLJS.n($CLJS.n(c)?d:c)&&(d=$CLJS.j1(a,d),$CLJS.n(d))){d=$CLJS.T.h(d);a=$CLJS.T.h($CLJS.$0(a,c));a=$CLJS.ha.j?$CLJS.ha.j("%s__via__%s",d,a):$CLJS.ha.call(null,"%s__via__%s",d,a);break a}a=null}return $CLJS.n(a)?$CLJS.l1(a,$CLJS.T.h(b)):$CLJS.T.h(b)};
$CLJS.n1=function(a,b,c,d){var e=$CLJS.Wf.j($CLJS.Sg,$CLJS.cf.h($CLJS.YJ),c);return $CLJS.Wf.j($CLJS.xf,$CLJS.Yj.l($CLJS.ef($CLJS.LY),$CLJS.ef($CLJS.Ei),$CLJS.ef($CLJS.Yj.g($CLJS.Yk,$CLJS.Ei)),$CLJS.H([$CLJS.cf.h(function(f){f=$CLJS.O(f);var k=$CLJS.K.g(f,$CLJS.Ei),l=$CLJS.K.g(f,$CLJS.LY);return $CLJS.R.l($CLJS.$0(a,l),$CLJS.C_,k,$CLJS.H([$CLJS.D_,$CLJS.eZ.h(f)]))}),$CLJS.Zj.h(function(f){return $CLJS.Dd(e,$CLJS.YJ.h(f))}),$CLJS.gD(function(f){f=$CLJS.O(f);var k=$CLJS.K.g(f,$CLJS.YJ),l=$CLJS.K.g(f,
$CLJS.C_),m=$CLJS.K.g(f,$CLJS.D_);f=$CLJS.d_(a,k);return function v(u){return new $CLJS.ie(null,function(){for(;;){var x=$CLJS.y(u);if(x){if($CLJS.wd(x)){var A=$CLJS.ic(x),C=$CLJS.D(A),G=$CLJS.le(C);return function(){for(var X=0;;)if(X<C){var W=$CLJS.id(A,X),da=$CLJS.R.l(W,$CLJS.XJ,l,$CLJS.H([$CLJS.z_,m,$CLJS.LJ,$CLJS.GY,$CLJS.uY,$CLJS.T.h(W)]));W=G;var ra=$CLJS.R,Ma=ra.j,sb=da;da=$CLJS.m1(a,da);da=d.h?d.h(da):d.call(null,da);ra=Ma.call(ra,sb,$CLJS.KY,da);W.add(ra);X+=1}else return!0}()?$CLJS.oe($CLJS.qe(G),
v($CLJS.jc(x))):$CLJS.oe($CLJS.qe(G),null)}var J=$CLJS.z(x),S=$CLJS.R.l(J,$CLJS.XJ,l,$CLJS.H([$CLJS.z_,m,$CLJS.LJ,$CLJS.GY,$CLJS.uY,$CLJS.T.h(J)]));return $CLJS.ae($CLJS.R.j(S,$CLJS.KY,function(){var X=$CLJS.m1(a,S);return d.h?d.h(X):d.call(null,X)}()),v($CLJS.Hc(x)))}return null}},null,null)}($CLJS.X_.v(a,b,f,new $CLJS.h(null,2,[$CLJS.H_,d,$CLJS.B_,!1],null)))})])),c)};$CLJS.o1=function(a,b){return $CLJS.e1.g(a,b)};$CLJS.p1=function(a){return $CLJS.hD("Question {0}",$CLJS.H([$CLJS.xh.l($CLJS.H([a]))]))};
$CLJS.q1=function(a,b){var c=$CLJS.SD.h(b);$CLJS.n(c)||(c=$CLJS.RY.h(b),$CLJS.n(c)||(c=$CLJS.QY.h(b),c=null!=c?$CLJS.W_.h($CLJS.o1(a,$CLJS.WX.h(c))):null));return $CLJS.n(c)?(c=$CLJS.Be($CLJS.td(c)?$CLJS.AV.h(c):$CLJS.sd(c)?c:null),$CLJS.n(c)?$CLJS.ak.g($CLJS.Xs(Spa,a,b),c):null):null};$CLJS.r1=new $CLJS.M("metabase.lib.card","force-broken-id-refs","metabase.lib.card/force-broken-id-refs",-868945458);$CLJS.s1=new $CLJS.M(null,"field-ref","field-ref",-1699303428);$CLJS.M_.m(null,$CLJS.gK,function(a,b,c){return $CLJS.zz($CLJS.mD,$CLJS.T)(c)});$CLJS.S_.m(null,$CLJS.gK,function(a,b,c){a=$CLJS.O(c);b=$CLJS.K.g(a,$CLJS.T);c=$CLJS.K.g(a,$CLJS.mD);return $CLJS.Ra(c)?$CLJS.R.j(a,$CLJS.mD,$CLJS.i1.g($CLJS.h1,b)):a});
$CLJS.X_.m(null,$CLJS.gK,function(a,b,c,d){c=$CLJS.O(c);var e=$CLJS.K.g(c,$CLJS.SD),f=$CLJS.K.g(c,$CLJS.RY);d=$CLJS.O(d);var k=$CLJS.K.g(d,$CLJS.B_),l=$CLJS.K.g(d,$CLJS.H_);return $CLJS.bf.g($CLJS.W_.v(a,b,c,d),$CLJS.n(k)?$CLJS.n1(a,b,$CLJS.bf.g(e,f),l):null)});$CLJS.P_.m(null,$CLJS.MU,function(a,b){var c=$CLJS.DV(a,b);c=$CLJS.O(c);c=$CLJS.K.g(c,$CLJS.MU);if($CLJS.n(c)){var d=$CLJS.f_(a,c);a=$CLJS.n(d)?$CLJS.l_.v(a,b,d,$CLJS.m_):null;return $CLJS.n(a)?a:$CLJS.p1(c)}return null});
var Spa=function(){function a(d,e,f){var k=$CLJS.Rj.g($CLJS.Ez(f,$CLJS.eA),$CLJS.s1);f=$CLJS.qk.l($CLJS.H([new $CLJS.h(null,2,[$CLJS.tA,$CLJS.Zi,$CLJS.Vs,$CLJS.JJ],null),function(){var l=$CLJS.Ei.h(k);if($CLJS.n(l))try{return $CLJS.$0(d,l)}catch(m){return null}else return null}(),k,new $CLJS.h(null,3,[$CLJS.Vs,$CLJS.JJ,$CLJS.LJ,$CLJS.OY,$CLJS.uY,$CLJS.zz($CLJS.uY,$CLJS.T)(k)],null)]));f=$CLJS.n(e)?$CLJS.R.j(f,$CLJS.YY,$CLJS.W0(e)):f;e=$CLJS.n($CLJS.n(!0)?$CLJS.Ra(e)||$CLJS.Ra($CLJS.CY.h($CLJS.f_(d,
$CLJS.W0(e)))):!0)?$CLJS.R.j(f,$CLJS.r1,!0):f;return $CLJS.Xj.g($CLJS.nj.h(k),$CLJS.Xh)?$CLJS.R.j(e,$CLJS.LY,null):e}function b(d,e){return c.j?c.j(d,null,e):c.call(null,d,null,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+arguments.length);};c.g=b;c.j=a;return c}();
$CLJS.V_.m(null,$CLJS.gK,function(a,b,c,d){b=$CLJS.O(d);var e=$CLJS.K.g(b,$CLJS.H_);return $CLJS.ak.g(function(f){var k=$CLJS.pB($CLJS.KY,$CLJS.uY,$CLJS.T)(f);return $CLJS.R.j(f,$CLJS.KY,e.h?e.h(k):e.call(null,k))},$CLJS.q1(a,c))});